const URL = {
    testbed: {
        PROCTUR_API_URL         : 'https://test999.proctur.com/StdMgmtWebAPI',
        CUSTOM_PRODUCT_URL      : 'https://productsit.proctur.com',
        OLD_EXAMDESK_API_URL    : 'https://test999.examdesk.co/api/v1',
        EXAMDESK_URL            : 'https://test999.examdesk.co',
        EXAMDESK_API_URL        : 'https://apitest.examdesk.co/v1',
        EXAMDESK_API_URL_2      : 'https://apitest.examdesk.co/v1',
        WEB_TEST_PANEL_URL      : 'http://testpanel999.examdesk.co',
        REPORT_DOWNLOAD_URL     : 'https://test999.examdesk.co/htmltopdf/?url=https://test999.examdesk.co/administrator/printTest/',
        REPORT_PREVIEW_URL      : 'https://test999.examdesk.co/administrator/printTest/advancedReport/'
    },
    prod: {
        PROCTUR_API_URL         : 'https://api.proctur.com/StdMgmtWebAPI',
        CUSTOM_PRODUCT_URL      : 'https://productprod.proctur.com',
        OLD_EXAMDESK_API_URL    : 'https://onlinetest.proctur.com/v1',
        EXAMDESK_URL            : 'https://onlinetest.proctur.com',
        EXAMDESK_API_URL        : 'https://loadapi.proctur.com/v1',
        EXAMDESK_API_URL_2      : 'https://exdapi.proctur.com/v1',
        WEB_TEST_PANEL_URL      : 'https://onlinetest.eduims.com',
        REPORT_DOWNLOAD_URL     : 'https://onlinetest.proctur.com/htmltopdf/?url=https://onlinetest.proctur.com/administrator/printTest/',
        REPORT_PREVIEW_URL      : 'https://onlinetest.proctur.com/administrator/printTest/advancedReport/'
    },
    uat: {
        PROCTUR_API_URL         : 'https://uat.proctur.com/StdMgmtWebAPI',
        CUSTOM_PRODUCT_URL      : 'https://productuat.proctur.com',
        OLD_EXAMDESK_API_URL    : 'https://onlinetestapi.proctur.com/v1',
        EXAMDESK_URL            : 'https://onlinetestuat.proctur.com',
        EXAMDESK_API_URL        : 'https://onlinetestapi.proctur.com/v1',
        EXAMDESK_API_URL_2      : 'https://onlinetestapi.proctur.com/v1',
        WEB_TEST_PANEL_URL      : 'https://starttest.proctur.com',
        REPORT_DOWNLOAD_URL     : 'https://pdf.proctur.com/?url=https://onlinetestapi.proctur.com/administrator/printTest/',
        REPORT_PREVIEW_URL      : 'https://onlinetestapi.proctur.com/administrator/printTest/advancedReport/'
    },
    sit: {
        PROCTUR_API_URL         : 'https://apiv2.proctur.com/StdMgmtWebAPI',
        CUSTOM_PRODUCT_URL      : 'https://devsit.proctur.com',
        OLD_EXAMDESK_API_URL    : 'https://sitapiv2.examdesk.co/v1',
        EXAMDESK_URL            : 'https://sitv2.examdesk.co',
        EXAMDESK_API_URL        : 'https://sitapiv2.examdesk.co/v1',
        EXAMDESK_API_URL_2      : 'https://sitapiv2.examdesk.co/v1',
        WEB_TEST_PANEL_URL      : 'https://sittestv2.examdesk.co',
        REPORT_DOWNLOAD_URL     : 'https://sitv2.examdesk.co/htmltopdf/?url=https://sitv2.examdesk.co/administrator/printTest/',
        REPORT_PREVIEW_URL      : 'https://sitv2.examdesk.co/administrator/printTest/advancedReport/'
    },
}
export default URL;
